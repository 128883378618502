import React, {Component} from "react";
import Button from "../../basics/button/Button";
import {connect} from "react-redux";
import logo from "../../../resources/images/logos/fetura_cloud_colour.svg"
import {ModalName as EditSowCycleModalName} from "../../modals-new/edit-sow-cycle/EditSowCycleModal";
import "./_sow.card.scss";
import Card from "../../basics/card/Card";
import TextSwitch from "../../basics/text-switch/TextSwitch";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";
import {get, isEmpty, isEqual, uniqBy} from "lodash";
import CycleGridHeader from "../../animal-documents/renderers/CycleGridHeader";
import moment from "moment";
import * as EventTypes from "validators-schema/Api/constants/eventTypes";
import animalsDB from "../../../database/animalsDB";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import CycleNumberRenderer from "../../animal-documents/renderers/CycleNumberRenderer";
import InseminationRenderer from "../../animal-documents/renderers/InseminationRenderer";
import USGRenderer from "../../animal-documents/renderers/USGRenderer";
import ParturitionRenderer from "../../animal-documents/renderers/ParturitionRenderer";
import FallPigletsRenderer from "../../animal-documents/renderers/FallPigletsRenderer";
import MommySeparationsRenderer from "../../animal-documents/renderers/MommySeparationsRenderer";
import SeparationRenderer from "../../animal-documents/renderers/SeparationRenderer";
import ActiveNipplesRenderer from "../../animal-documents/renderers/ActiveNipplesRenderer";
import WeightingSowRenderer from "../../animal-documents/renderers/WeightingSowRenderer";
import NoPregnancyRenderer from "../../animal-documents/renderers/NoPregnancyRenderer";
import MommyRenderer from "../../animal-documents/renderers/MommyRenderer";
import ExpandRenderer from "../../animal-documents/renderers/ExpandRenderer";
import {getCorrectValidatedEventsData} from "../../../utils/AnimalDocumentsUtils";
import {getCycleReason} from "../../../utils/CycleUtils";

function mapStateToProps(state) {
    return {
        animal: state.animalDocuments.selectedItem,
        cycleTable: state.animalDocuments.cycleTable,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class SowCardGridMinimal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: 1,
            cycleData: [],
            expanded: {rows: [], opened: false}
        };
        this.table = React.createRef();
    }

    static getBoarName(BoarID) {
        const animal = animalsDB.getAnimalById(BoarID);
        return get(animal, "AnmNo1", "-");
    }

    static weightValueFormatter(value) {
        return !!value ? convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1}) : "";
    };

    static getCycleData(props) {
        const {cycleTable, animal} = props;
        const _DateFormat = "DD.MM.YY";
        const validatedEventsData = getCorrectValidatedEventsData(cycleTable, animal);
        return validatedEventsData.map((row, index) => ({
            cycle: row.cycle,
            key: index,
            invalidEvents: get(row, "invalidEvents", []),
            inseminations: get(row, EventTypes.INSEMINATION, []).map((e) => ({
                boar: SowCardGridMinimal.getBoarName(get(e, "EvData.BoarID")),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.INSEMINATION),
                operators: get(row, EventTypes.INSEMINATION, []).map((o) => o.EvData.OperID)
            })),
            usgs: get(row, EventTypes.USG, []).map((e) => ({
                result: get(e, "EvData.Pregnant"),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.USG),
                operators: get(row, EventTypes.USG, []).map((o) => o.EvData.OperID)
            })),
            parturitions: get(row, EventTypes.PARTURITION, []).map((e) => ({
                alive: get(e, "EvData.HealthyCnt", 0),
                dead: get(e, "EvData.DeadCnt", 0),
                mummies: get(e, "EvData.MummyCnt", 0),
                avgWeight: get(e, "EvData.HealthyCnt", 0) > 0 ? SowCardGridMinimal.weightValueFormatter(get(e, "EvData.Weight") / get(e, "EvData.HealthyCnt")) : 0,
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.PARTURITION),
                operators: get(row, EventTypes.PARTURITION, []).map((o) => o.EvData.OperID)
            })),
            separations: get(row, EventTypes.SEPARATION, []).map((e) => ({
                amount: get(e, "EvData.PiCnt"),
                weight: SowCardGridMinimal.weightValueFormatter(get(e, "EvData.PiWeight")),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.SEPARATION_TO_MOMMY),
                operators: get(row, EventTypes.SEPARATION, []).map((o) => o.EvData.OperID)
            })),
            falls: get(row, EventTypes.FALL_PIGLETS, []).map((e) => ({
                amount: get(e, "EvData.Piglets"),
                weight: get(e, "EvData.Weight") || 0,
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.FALL_PIGLETS),
                operators: get(row, EventTypes.FALL_PIGLETS, []).map((o) => o.EvData.OperID)
            })),
            weightings: get(row, EventTypes.WEIGHTING, []).map((e) => ({
                reason: get(e, "EvData.Reasn"),
                weight: (get(e, "EvData.Weight") / get(e, "EvData.AnmCnt")),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.WEIGHTING),
                operators: get(row, EventTypes.WEIGHTING, []).map((o) => o.EvData.OperID)
            })),
            separationsMommy: get(row, EventTypes.SEPARATION_TO_MOMMY, []).map((e) => ({
                amount: get(e, "EvData.PiCnt", 0),
                weight: get(e, "EvData.PiWeight") || 0,
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.SEPARATION_TO_MOMMY),
                operators: get(row, EventTypes.SEPARATION_TO_MOMMY, []).map((o) => o.EvData.OperID)
            })),
            mommys: get(row, EventTypes.MOMMY, []).map((e) => ({
                amount: get(e, "EvData.PiCnt", 0),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.MOMMY),
                operators: get(row, EventTypes.MOMMY, []).map((o) => o.EvData.OperID)
            })),
            noPregnancy: get(row, EventTypes.NO_PREGNANCY, []).map((e) => ({
                reason: get(e, "EvData.Reason"),
                date: moment(get(e, "EvTime")).format(_DateFormat),
                comment: get(e, "Comment", "-"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.NO_PREGNANCY),
                operators: get(row, EventTypes.NO_PREGNANCY, []).map((o) => o.EvData.OperID)
            })),
            activeNipples: get(row, EventTypes.ACTIVE_NIPPLES, []).map((e) => ({
                nipples: get(e, "EvData.Nipples"),
                invalidEvents: get(row, "invalidEvents", []).filter((o) => o.EvCode === EventTypes.ACTIVE_NIPPLES),
                operators: get(row, EventTypes.ACTIVE_NIPPLES, []).map((o) => o.EvData.OperID)
            }))
        }));
    }

    showEditModal = () => {
        const {show} = this.props;
        show(EditSowCycleModalName, {
            animal: this.props.animal,
            cycles: this.props.cycleTable
        })
    };

    getCycleRenderClass(kind, row) {
        const invalidEventsInRow = this.getInvalidEventsInRow(kind, row);
        const invalidEventType = invalidEventsInRow.find((e) => e.EvCode === kind);
        if (!!invalidEventType) {
            return `invalid-event ${invalidEventType.type || "error"}`;
        }
    }

    getInvalidEventsInRow(kind, row) {
        const {cycleData} = this.state;
        if (!isEmpty(cycleData)) {
            return uniqBy(cycleData.find(d => d.key === row).invalidEvents, (el) => el.EvCode)
        }
        return [];
    }

    getTooltipContent(kind, row, message) {
        try {
            const invalidEventsInRow = this.getInvalidEventsInRow(kind, row).filter(ev => ev.EvCode === kind);
            if (!isEmpty(invalidEventsInRow)) {
                return (
                    <div>
                        <b>{message}</b>
                        {
                            invalidEventsInRow.map(ev => (
                                <li>
                                    {getCycleReason(ev.Reason, ev.AdditionalData)}
                                </li>
                            ))
                        }
                    </div>
                )
            } else return null;
        } catch (e) {
            return null;
        }
    }

    onExpandedRowClick = (value, opened) => {
        const {expanded: {rows}} = this.state;
        let _value = rows.slice(0);
        let exist = _value.filter(o => o === value).length;
        if (exist && !opened) {
            _value = _value.filter(idx => idx !== value);
        } else if (!exist && opened) {
            _value.push(value);
        }
        this.setState({
            expanded: {rows: _value}
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cycleTable} = this.props;
        if (!isEqual(prevProps.cycleTable, cycleTable)) {
            this.setState({
                cycleData: SowCardGridMinimal.getCycleData(this.props)
            });
        }
    }

    getSwitchOptions = (props) => {
        const {t} = props;
        return [
            {
                name: t("basic"),
                value: 1
            },
            {
                name: t("advanced"),
                value: 2
            }
        ];
    };

    onSwitchView = (value) => {
        this.setState({
            view: value
        });
    };

    onPrintClick = () => {
        const {t} = this.props;
        let outerHTML = this.table.current.outerHTML;
        outerHTML = outerHTML.replace(new RegExp('<i class="fas fa-fw fa-check"></i>', 'g'), "✓");
        outerHTML = outerHTML.replace(new RegExp('<i class="fas fa-fw fa-times"></i>', 'g'), "✕");
        outerHTML = outerHTML.replace(new RegExp('<i class="fas fa-fw fa-redo-alt"></i>', 'g'), "?");
        let infoBox = document.getElementById("info-box");
        let html = `
            <html>
                <head>
                    <title>${t("animalDocuments.sowCard")}</title>
                    <link rel="stylesheet" href="${window.location.origin}/print.css" type="text/css" crossorigin="anonymous"/>
                </head>
                <body>
                    <header>
                        <img src='${logo}'/>
                        <h1>${t("animalDocuments.sowCard")} ${this.props.animal.AnmNo1}</h1>
                    </header>
                    <div>
                        ${infoBox.outerHTML}
                    </div>
                    ${outerHTML}
                </body>
            </html>
        `;
        let newWindow = window.open("");
        newWindow.document.write(html);
        newWindow.document.close();
        setTimeout(() => {
            newWindow.focus();
            newWindow.print();
            newWindow.close();
        }, 1000);
    };

    getGridHeaders = () => {
        const {view} = this.state;
        const {t} = this.props;
        return (
            <>
                {
                    view === 2 &&
                    <CycleGridHeader className={'non-print-first-record'} title={t("animalDocuments.expand")}/>
                }
                <CycleGridHeader title={t("animalDocuments.cycleNumber")}/>
                <CycleGridHeader title={t("animalDocuments.insemination")}
                                 columns={[{name: t("date")}, {name: t("boar")}]}/>
                <CycleGridHeader title={t("usg")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("result")}]}/>
                <CycleGridHeader title={t("animalDocuments.birth")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("alive")}, {name: t("dead")}, {name: t("mummies")}, {name: t("animalDocuments.averageWeight")}]}/>
                <CycleGridHeader title={t("fallPiglets")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("amount")}, {name: t("animalDocuments.weight")}]}/>
                <CycleGridHeader title={t("animalDocuments.mommySeparation")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("amount")}, {name: t("animalDocuments.weight")}]}/>
                <CycleGridHeader title={t("mommy")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("amount")}]}/>
                <CycleGridHeader title={t("separation")}
                                 columns={[...(view !== 1 ? [{name: t("date")}] : []), {name: t("amount")}, {name: t("animalDocuments.weight")}]}/>
                <CycleGridHeader title={t("animalDocuments.activeNipples")} columns={[{name: t("amount")}]}/>
                <CycleGridHeader title={t("weighting")} columns={[...(view !== 1 ? [{name: t("date")}] : []), {
                    name: t("weight"),
                    className: "print-last-record"
                }]} className="print-last"/>
                <CycleGridHeader title={t("noPregnancy")} columns={[...(view !== 1 ? [{name: t("date")}] : []), {
                    name: t("reason"),
                    className: "last-header"
                }]} className="print-display-none last-main-header"/>
            </>
        )
    };

    getEmptyRows = (neededRows) => {
        const {cycleData} = this.state;
        const emptyRows = [];
        for (let count = 1; count <= neededRows; count++) {
            emptyRows.push({
                shouldExpand: false,
                cycle: cycleData[cycleData.length - 1] ? cycleData[cycleData.length - 1].cycle + count : (cycleData.length - 1 + count),
                inseminations: [],
                usgs: [],
                parturitions: [],
                separations: [],
                falls: [],
                weightings: [],
                separationsMommy: [],
                mommys: [],
                noPregnancy: [],
                activeNipples: []
            });
        }
        return emptyRows;
    };

    render() {
        const {view, cycleData, expanded} = this.state;
        const {t} = this.props;
        const switchOptions = this.getSwitchOptions(this.props);
        const gridHeaders = this.getGridHeaders();
        const isAdvanced = view === 2;
        const neededRows = cycleData.length < 6 ? (6 - cycleData.length) : 0;
        return (
            <Card>
                <div className="sow-card-header">
                    <h5>{t("animalDocuments.cyclesHistory")}&nbsp;</h5>
                    <TextSwitch onChange={this.onSwitchView} value={view} options={switchOptions}/>
                    <Button buttonStyle="round" icon={<i className="fas fa-pencil-alt"/>} onClick={this.showEditModal}/>
                </div>
                <div className="sow-card">
                    <Button icon={<i className="far fa-print"/>} id="print-button" buttonColor="success"
                            onClick={this.onPrintClick}>
                        {t("grid.print1")}
                    </Button>
                    <div className="cycle-grid-container" ref={this.table}>
                        <div className="cycle-grid">
                            {gridHeaders}
                            {
                                cycleData.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            isAdvanced && <ExpandRenderer expanded={expanded} cycle={row}
                                                                          onPress={this.onExpandedRowClick}/>
                                        }
                                        <CycleNumberRenderer cycle={row}/>
                                        <InseminationRenderer
                                            cycleRenderClass={this.getCycleRenderClass(EventTypes.INSEMINATION, index)}
                                            expanded={expanded} isAdvanced={isAdvanced} cycle={row}
                                            tooltipContent={this.getTooltipContent(EventTypes.INSEMINATION, index, t("animalDocuments.insemination"))}
                                        />
                                        <USGRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}
                                                     cycleRenderClass={this.getCycleRenderClass(EventTypes.USG, index)}
                                                     tooltipContent={this.getTooltipContent(EventTypes.USG, index, 'USG')}
                                        />
                                        <ParturitionRenderer isAdvanced={isAdvanced} cycle={row}
                                                             tooltipContent={this.getTooltipContent(EventTypes.PARTURITION, index, t("animalDocuments.birth"))}
                                                             cycleRenderClass={this.getCycleRenderClass(EventTypes.PARTURITION, index)}
                                        />
                                        <FallPigletsRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}
                                                             tooltipContent={this.getTooltipContent(EventTypes.FALL_PIGLETS, index, t("fallPiglets"))}
                                                             cycleRenderClass={this.getCycleRenderClass(EventTypes.FALL_PIGLETS, index)}
                                        />
                                        <MommySeparationsRenderer expanded={expanded} isAdvanced={isAdvanced}
                                                                  cycle={row}
                                                                  tooltipContent={this.getTooltipContent(EventTypes.SEPARATION_TO_MOMMY, index, t("animalDocuments.mommySeparation"))}
                                                                  cycleRenderClass={this.getCycleRenderClass(EventTypes.SEPARATION_TO_MOMMY, index)}
                                        />
                                        <MommyRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}
                                                       tooltipContent={this.getTooltipContent(EventTypes.MOMMY, index, t("mommy"))}
                                                       cycleRenderClass={this.getCycleRenderClass(EventTypes.MOMMY, index)}
                                        />
                                        <SeparationRenderer isAdvanced={isAdvanced} cycle={row}
                                                            tooltipContent={this.getTooltipContent(EventTypes.SEPARATION, index, t("separation"))}
                                                            cycleRenderClass={this.getCycleRenderClass(EventTypes.SEPARATION, index)}
                                        />
                                        <ActiveNipplesRenderer cycle={row}
                                                               tooltipContent={this.getTooltipContent(EventTypes.ACTIVE_NIPPLES, index, t("animalDocuments.activeNipples"))}
                                                               cycleRenderClass={this.getCycleRenderClass(EventTypes.ACTIVE_NIPPLES, index)}
                                        />
                                        <WeightingSowRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <NoPregnancyRenderer isAdvanced={isAdvanced} cycle={row}/>
                                    </React.Fragment>
                                ))
                            }
                            {
                                this.getEmptyRows(neededRows).map((row, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            isAdvanced && <ExpandRenderer expanded={expanded} cycle={row}
                                                                          onPress={this.onExpandedRowClick}/>
                                        }
                                        <CycleNumberRenderer cycle={row}/>
                                        <InseminationRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <USGRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <ParturitionRenderer isAdvanced={isAdvanced} cycle={row}/>
                                        <FallPigletsRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <MommySeparationsRenderer expanded={expanded} isAdvanced={isAdvanced}
                                                                  cycle={row}/>
                                        <MommyRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <SeparationRenderer isAdvanced={isAdvanced} cycle={row}/>
                                        <ActiveNipplesRenderer cycle={row}/>
                                        <WeightingSowRenderer expanded={expanded} isAdvanced={isAdvanced} cycle={row}/>
                                        <NoPregnancyRenderer isAdvanced={isAdvanced} cycle={row}/>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SowCardGridMinimal);
