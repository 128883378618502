import React, {Component} from 'react';
import ListRow from "./row/ListRow";
import _ from "lodash";
import {CellMeasurer, CellMeasurerCache, WindowScroller} from "react-virtualized";
import {List as VirtualizedList} from "react-virtualized/dist/commonjs/List";
import {getRemToPx} from "../../utils/DOMUtils";

class _ListMobile extends Component {

    onRowsRendered = _.debounce(({stopIndex}) => {
        const {loadMoreCallback, data = [], pageSize} = this.props;
        if (_.isFunction(loadMoreCallback) && (this.lastStopIndex !== stopIndex)) {
            this.lastStopIndex = stopIndex;
            if (((data.length - 1) === stopIndex)) {
                const page = Math.floor(data.length / pageSize);
                loadMoreCallback({
                    currentPage: page,
                    nextPage: page + 1,
                    length: data.length,
                    pageSize: pageSize,
                    lastItem: data.length ? data[data.length - 1] : null
                })
            }

        }
    }, 125);

    constructor(props) {
        super(props);
        this.lastStopIndex = 0;
        this.cache = new CellMeasurerCache({
            defaultHeight: getRemToPx(7),
            fixedWidth: true,
            keyMapper: (rowIndex) => {
                const data = this.props.data[rowIndex] || {};
                const id = _.get(data, "_id") || rowIndex;
                return `${id}`;
            }
        });
    }

    rowRenderer = ({index, style, key: keyProps, parent}) => {
        const {data, onExpand, options} = this.props;
        const {title, text, timestamp, subtitle, _id, _passThrough} = data[index] || {};
        return (
            <CellMeasurer
                cache={this.cache}
                columnIndex={0}
                key={keyProps}
                parent={parent}
                rowIndex={index}
            >
                {({measure}) => (
                    <ListRow _id={_id} _passThrough={_passThrough} options={options} style={style} title={title} text={text}
                             timestamp={timestamp} subtitle={subtitle}
                             onExpand={onExpand}/>
                )}
            </CellMeasurer>
        );
    };

    render() {
        const {data, loading} = this.props;
        return (
            <div>
                <WindowScroller onResize={this.clearCache}>
                    {
                        ({height, isScrolling, scrollTop, onChildScroll}) =>
                            <VirtualizedList
                                rowCount={data.length}
                                width={window.innerWidth} // maxwidth
                                rowRenderer={this.rowRenderer}
                                scrollTop={scrollTop}
                                autoHeight
                                height={height}
                                onRowsRendered={this.onRowsRendered}
                                deferredMeasurementCache={this.cache}
                                rowHeight={this.cache.rowHeight}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                overscanRowCount={3}
                                className={"w-100 overflow-auto"}
                            />
                    }
                </WindowScroller>
                {
                    loading &&
                    <div className={"w-100 d-flex justify-content-center align-items-center"}>
                        <i className={"fas fa-circle-notch fa-spin"}/>
                    </div>
                }
            </div>
        );
    }
}

_ListMobile.propTypes = {};

export default _ListMobile;