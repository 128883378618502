import React, {Component} from 'react';
import {getTotalAnimals} from "../../selectors/inventorySelector";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {AnimalTypes} from "../../constans/animalTypes";

class InventoryTotal extends Component {

    renderRow = (type) => {
        const {total, animalTypeMap = new Map()} = this.props;
        return (
            <Col xs={4} sm={4} md={2}>
                <h4 className={"justify-content-center"}>{total[type].total}</h4>
                {animalTypeMap.get(type)}
            </Col>
        )
    };

    render() {
        return (
            <Row className={"text-center"} style={{"justify-content": "center"}}>
                {this.renderRow(AnimalTypes.RENOVATION_SOW)}
                {this.renderRow(AnimalTypes.SOW)}
                {this.renderRow(AnimalTypes.PORKER)}
                {this.renderRow(AnimalTypes.BOAR)}
                {this.renderRow(AnimalTypes.PIGLET)}
            </Row>
        );
    }
}

InventoryTotal = connect(state => ({
    total: getTotalAnimals(state),
    animalTypeMap: state.inventory.animalTypeMap
}))(InventoryTotal);
export default InventoryTotal;
