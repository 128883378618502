import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import queryString from "query-string"
import {inventoryShowCollapsed, inventoryShowDead} from "../../../actions/inventoryActions";
import {withCookies} from "react-cookie";
import Switch from "../../basics/switch/Switch";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";


class SwitchActions extends Component {

    constructor(props) {
        super(props);
        const {cookies, dispatch, location: {pathname, search}, history} = this.props;
        //zczytanie z linku
        let {showDead, showCollapsed} = SwitchActions.getQueryValue(this.props);
        if (showDead === null) {
            //zczytanie z cookiesow
            showDead = !!(cookies.get("inventoryView") || {}).showDead;
        }
        if (showCollapsed === null) {
            showCollapsed = !!(cookies.get("inventoryView") || {}).showCollapsed;
        }
        let searchParams = new URLSearchParams(search);
        if (showDead) {
            searchParams.set(`showDead`, "true");
        } else {
            searchParams.delete(`showDead`);
        }
        if (showCollapsed) {
            searchParams.set(`showCollapsed`, "true");
        } else {
            searchParams.delete(`showCollapsed`);
        }
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
        dispatch(inventoryShowDead(!!showDead));
        dispatch(inventoryShowCollapsed(!!showCollapsed));
    }

    static getQueryValue = (props) => {
        const query = queryString.parse(props.location.search);
        if (Object.keys(query).length) {
            return {
                showDead: query.showDead === "true" || false,
                showCollapsed: query.showCollapsed === "true" || false
            }
        }
        return {showDead: null, showCollapsed: null};
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newValue = SwitchActions.getQueryValue(this.props);
        const oldValue = SwitchActions.getQueryValue(prevProps);
        if (newValue.showDead !== oldValue.showDead) {
            this.props.dispatch(inventoryShowDead(!!newValue.showDead));
        }
        if (newValue.showCollapsed !== oldValue.showCollapsed) {
            this.props.dispatch(inventoryShowCollapsed(!!newValue.showCollapsed));
        }
    }

    componentWillUnmount() {
        const {cookies, showDead, showCollapsed} = this.props;
        const cookie = cookies.get("inventoryView") || {};
        cookie.showDead = !!showDead;
        cookie.showCollapsed = !!showCollapsed;
        cookies.set("inventoryView", cookie, {
            path: "/",
            expires: moment().add(1, "year").toDate()
        });

    }

    handleOnChange = (newValue, key) => {
        console.log(newValue, key);
        const {location: {pathname, search}, history} = this.props;
        let searchParams = new URLSearchParams(search);
        if (newValue) {
            searchParams.set(`${key}`, "true");
        } else {
            searchParams.delete(`${key}`);
        }
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
    };

    handleDead = (value) => {
        this.handleOnChange(value, "showDead");
    };

    handleCollapse = (value) => {
        this.handleOnChange(value, "showCollapsed");
    };

    render() {
        const {showDead, showCollapsed, t} = this.props;
        return (
            <>
                <Row>
                    <Col className={"col-auto mr-1"}>
                        <Switch id={"show-dead-switch"} reverseOrder={true}
                                label={t("farms.tabs.inventoryTab.showDeadSold")}
                                onChange={this.handleDead}
                                value={!!showDead}/>
                    </Col>
                    <Col className={"col-auto"}>
                        <Switch id={"show-collapsed-switch"} reverseOrder={true}
                                label={t("farms.tabs.inventoryTab.showCollapsed")}
                                onChange={this.handleCollapse}
                                value={!!showCollapsed}/>
                    </Col>
                </Row>


            </>
        );
    }
}

SwitchActions = connect(
    (state) => ({
        showDead: state.inventory.showDead,
        showCollapsed: state.inventory.showCollapsed
    }),
)(SwitchActions);

SwitchActions = withTranslation()(SwitchActions);

export default SwitchActions = withRouter(withCookies(SwitchActions));
