import React, {PureComponent} from 'react';
import ListItem from "../basics/list/list-item/ListItem";
import {Col} from "react-bootstrap";

export default class ListItemFullWidthRow extends PureComponent {
    render() {
        const {text} = this.props;
        return (
            <ListItem className={"pt-2 pb-2 opacity-50"}>
                <Col xs={12}>
                    <h5 className="justify-content-start align-items-center">
                        {text}
                    </h5>
                </Col>
            </ListItem>
        );
    }
}