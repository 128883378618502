import React from "react";
import {Col, Row} from "react-bootstrap";
import logo from "../../resources/images/logos/fetura_cloud_colour.svg";
import Card from "../basics/card/Card";
import Menu from "../basics/menu/Menu";
import {Link} from "react-router-dom";
import HelpContactModal, {ModalName as ContactModalName} from "../modals-new/help-contact-modal/HelpContactModal";
import RequirementsModal, {ModalName as RequirementModalName} from "../modals-new/hardware-requirements/requirementsModal";
import {connect} from "react-redux";
import Api from "../../localization/ApiLanguage";
import MenuItem from "../basics/menu/MenuItem";
import PropTypes from "prop-types"
import "./_login.scss"
import {show} from "redux-modal";
import {bindActionCreators} from "redux";
import VersionFooter from "../basics/version-footer/VersionFooter";
import Button from "../basics/button/Button";
import {withTranslation} from "react-i18next";
import {fetchLanguage} from "../../actions/localizationActions";

export class LoginContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ev: null
        }
    }

    switchLanguage = async (lang) => {
        const {dispatch, i18n} = this.props;
        await i18n.changeLanguage(lang);
        await dispatch(fetchLanguage(lang));
    };

    getLanguages = () => {
        let list = [];
        Api.getLanguage().map((lang) => {
                if (lang.lang !== this.props.selectedLang) {
                    if (process.env.REACT_APP_STAGE === "local" || process.env.REACT_APP_STAGE === "development" || process.env.REACT_APP_STAGE === "staging") {
                        list.push(
                            <MenuItem key={lang.lang} onClick={() => this.switchLanguage(lang.lang)}>
                                {lang.lang.toUpperCase()}
                            </MenuItem>)
                    } else {
                        if (lang.lang !== "cimode") {
                            list.push(
                                <MenuItem key={lang.lang} onClick={() => this.switchLanguage(lang.lang)}>
                                    {lang.lang.toUpperCase()}
                                </MenuItem>)
                        }
                    }
                }
            }
        );
        return list;
    };

    onListItemClick(name) {
        this.props.onStateChange(name, {});
    }

    render() {
        const {t, selectedLang, children, showForgotPassword, showSignIn, showSendVerificationCode, onSendVerificationCodeClick} = this.props;
        const {ev} = this.state;
        return (
            <div className="login-box">
                <Row bsPrefix="row h-100 justify-content-center align-items-center w-100 mx-auto">
                    <Col xl={4} lg={6} md={8} sm={10} xs={12}>
                        <img src={logo} className={"fetura-logo w-75"} alt="fetura-logo"/>
                        <Card>
                            {children}
                        </Card>
                        <ul className={"mb-1"}>
                            {
                                showForgotPassword &&
                                <>
                                    <li>
                                        <a className={"pointer"}
                                           onClick={() => this.onListItemClick("forgotPassword")}>
                                            <i className="fa fa-chevron-right"/>{t('errors.doNotRememberPassword')}
                                        </a>
                                    </li>
                                    <li>
                                        <Link to={"/signup"}><i className="fa fa-chevron-right"/>{t('login.signUp')}
                                        </Link>
                                    </li>
                                </>
                            }
                            {
                                showSignIn &&
                                <li>
                                    <a className={"pointer"} onClick={() => this.onListItemClick("signIn")}>
                                        <i className="fa fa-chevron-right"/>{t('login.signIn')}
                                    </a>
                                </li>
                            }
                            {
                                showSendVerificationCode &&
                                <li>
                                    <a className={"pointer"} onClick={onSendVerificationCodeClick}>
                                        <i className="fa fa-chevron-right"/>{t('login.resendVerificationCode')}
                                    </a>
                                </li>
                            }
                            <li>
                                <Button onClick={(e) => this.setState({ev: e})} buttonStyle={"text"}
                                        icon={<i className={"fas fa-language"}/>}
                                >
                                    {selectedLang.toUpperCase()}
                                </Button>
                                <Menu event={ev}>
                                    {this.getLanguages()}
                                </Menu>
                            </li>
                        </ul>
                        <Row className="opacity-75 w-100 mb-4 text-left text-md-center">
                            <Col xs={6} md={3}>
                                <Link to={"/eula"}>{t('license')}</Link>
                            </Col>
                            <Col xs={6} md={3}>
                                <Link to={"/pricing"}>{t('login.pricing')}</Link>
                            </Col>
                            <Col xs={6} md={3}>
                                <a className={"pointer"} onClick={() => {
                                    this.props.show(ContactModalName, {})
                                }}>{t('contact')}</a>
                            </Col>
                            <Col xs={6} md={3}>
                                <a className={"pointer"} onClick={() => {
                                    this.props.show(RequirementModalName, {})
                                }}>{t('requirements.requirements')}</a>
                            </Col>
                        </Row>

                        <HelpContactModal/>
                        <RequirementsModal/>
                    </Col>

                </Row>
                <VersionFooter/>
            </div>
        );
    }

}

LoginContainer.propTypes = {
    onStateChange: PropTypes.func.isRequired,
    showForgotPassword: PropTypes.bool,
    showSignIn: PropTypes.bool,
    showSendVerificationCode: PropTypes.bool,
    onSendVerificationCodeClick: PropTypes.func
};

LoginContainer.defaultProps = {
    showForgotPassword: true,
    showSignIn: true,
    showSendVerificationCode: true
};

LoginContainer = connect(
    state => ({
        selectedLang: state.language.locale,
    }),
    dispatch => ({dispatch, ...bindActionCreators({show}, dispatch)})
)(LoginContainer);

export default withTranslation()(LoginContainer);
