import React, {Component} from 'react';
import {connect} from 'react-redux';
import TreeSelect from "../../basics/tree-select/TreeSelect";
import buildingsDB from "../../../database/buildingsDB";
import _ from "lodash";
// import {inventoryLocationFilter} from "../../../actions/inventoryActions";
import {withRouter} from "react-router-dom";
import queryString from "query-string"
import {getTreeSelectValueByLocationID} from "../../../utils/TreeSelectUtils";
import {getLocationID} from "../../../utils/BuildingUtils";
import {inventoryLocationFilter} from "../../../actions/inventoryActions";
import i18n from "../../../i18n";


class LocationSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            t: (val) => i18n.t(val)
        }
    }

    state = {
        ...LocationSelect.getValueFromProps(this.props)
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!_.isEqual(nextProps.buildings, prevState.buildings)) {
            return LocationSelect.getValueFromProps(nextProps);
        }
        const oldLocationID = getLocationID(_.get(prevState.value, "object"));
        if (!_.isEqual(oldLocationID, LocationSelect.getQueryLocation(nextProps))) {
            return {
                value: getTreeSelectValueByLocationID(prevState.treeOptions, (LocationSelect.getQueryLocation(nextProps)))
            }
        }
        return null;
    }

    static getQueryLocation = (props) => {
        const query = queryString.parse(props.location.search);
        try {
            return query.location || null
        } catch (e) {
            return null
        }
    };

    static getValueFromProps = (props) => {
        const options = buildingsDB.getObjectForTreeSelect(props.farm, false);
        return {
            treeOptions: buildingsDB.getObjectForTreeSelect(props.farm, false),
            buildings: props.buildings,
            value: getTreeSelectValueByLocationID(options, (LocationSelect.getQueryLocation(props)))
        }
    };

    componentDidMount() {
        this.props.dispatch(inventoryLocationFilter(getLocationID(_.get(this.state.value, "object"))));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newLocationID = getLocationID(_.get(this.state.value, "object"));
        const oldLocationID = getLocationID(_.get(prevState.value, "object"));
        if (newLocationID !== oldLocationID) {
            this.props.dispatch(inventoryLocationFilter(newLocationID))
        }
    }

    handleOnChange = (a) => {
        const {location: {pathname, search}, history} = this.props;
        let searchParams = new URLSearchParams(search);
        if (a) {
            searchParams.set("location", a.object[a.key]);
        } else {
            searchParams.delete("location");
        }
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
    };

    render() {
        const {t} = this.state;
        const {treeOptions = [], value} = this.state;
        return (
            <div>
                <TreeSelect clearButton={true} placeholder={t("location")} options={treeOptions}
                            expanded
                            onChange={this.handleOnChange}
                            value={value}/>
            </div>
        );
    }
}

LocationSelect = connect(
    (state) => ({
        buildings: state.farms.buildings,
        farm: state.location.farm,
    }),
)(LocationSelect);

export default LocationSelect = withRouter(LocationSelect);
