import React from "react";
import {ForgotPassword} from "aws-amplify-react";
import LoginContainer from "./LoginContainer";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import Button from "../basics/button/Button";
import InfoBox from "../basics/info-box/InfoBox";
import {Collapse} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class CustomForgotPassword extends ForgotPassword {

    constructor(props) {
        super(props);

        this.state = {
            delivery: null,
            resendedCode: false
        }
    }


    onSubmitSendVerificationCode = e => {
        this.send();
        e.preventDefault();
    };

    onSubmitSendNewPassword = e => {
        this.submit();
        e.preventDefault();
    };

    onResendVerificationCodeClick = () => {
        this.send();
        this.setState({
            resendedCode: true
        })
    }

    showComponent(theme) {
        console.log(this);
        const {onStateChange, t, error} = this.props;
        const {delivery, resendedCode} = this.state;
        console.log(this.state);
        return (
            <LoginContainer onStateChange={onStateChange} showForgotPassword={false}
                            showSendVerificationCode={!!delivery}
                            onSendVerificationCodeClick={this.onResendVerificationCodeClick}>
                {
                    delivery && !resendedCode &&
                    <InfoBox>{i18next.t("forgottenPassword.infoSend", {type: delivery.Destination})}</InfoBox>
                }
                {
                    resendedCode &&
                    <InfoBox>{i18next.t("forgottenPassword.resendedCode", {type: delivery.Destination})}</InfoBox>
                }
                <Collapse in={!!error}>
                    <div>
                        <InfoBox boxColor={"error"}>{error}</InfoBox>
                    </div>
                </Collapse>
                {
                    delivery &&
                    <form onSubmit={this.onSubmitSendNewPassword}>
                        <LabeledInput
                            id="code"
                            key="code"
                            name="code"
                            type="text"
                            label={t("forgottenPassword.verifyCode")}
                            onChange={this.handleInputChange}
                            onChangeWithEvent
                        />
                        <LabeledInput
                            id="password"
                            key="password"
                            name="password"
                            type="password"
                            autocomplete="off"
                            label={t("newPassword")}
                            onChange={this.handleInputChange}
                            onChangeWithEvent
                        />
                        <Button buttonColor={"primary"}>
                            {t("forgottenPassword.submit")}
                        </Button>
                    </form>
                }
                {
                    !delivery &&
                    <form onSubmit={this.onSubmitSendVerificationCode}>
                        <LabeledInput
                            id="username"
                            key="username"
                            name="username"
                            type="text"
                            label={t("login.login")}
                            onChange={this.handleInputChange}
                            onChangeWithEvent
                        />
                        <Button buttonColor={"primary"}>
                            {t("login.rememberPassword")}
                        </Button>
                    </form>
                }
            </LoginContainer>
        )
    }

}

export default withTranslation()(CustomForgotPassword);