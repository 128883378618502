import React, {Component} from 'react';
import ListRow from "./row/ListRow";
import Button from "../basics/button/Button";
import {isFunction} from "lodash";

class _ListDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {maxPages} = nextProps;
        if (maxPages < prevState.page) {
            return {
                page: maxPages
            }
        }
        return null;
    }


    rowRenderer = ({title, text, timestamp, subtitle, _id, _passThrough} = {}) => {
        const {onExpand, options, sizing} = this.props;
        return (
            <ListRow sizing={sizing} _id={_id} _passThrough={_passThrough} options={options} title={title} text={text}
                     timestamp={timestamp} subtitle={subtitle} onExpand={onExpand}/>
        )
    };

    cutPage = (page, pageSize, data) => {
        console.log(page, data, pageSize);
        return data.slice(page * pageSize, (page + 1) * pageSize);
    };

    pageNext = () => {
        const {page} = this.state;
        const {maxPages} = this.props;
        this.setState({
            page: Math.min(page + 1, maxPages - 1)
        })
    };

    pagePrev = () => {
        const {page} = this.state;
        this.setState({
            page: Math.max(page - 1, 0)
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loadMoreCallback, data = [], maxPages, pageSize} = this.props;
        const {page} = this.state;
        if (isFunction(loadMoreCallback)) {
            if ((prevState.page !== page) && ((maxPages - 1) === page)) {
                loadMoreCallback({
                    currentPage: page,
                    nextPage: page + 1,
                    length: data.length,
                    pageSize: pageSize,
                    lastItem: data.length ? data[data.length - 1] : null
                })
            }

        }
    }

    render() {
        const {data, pageSize, maxPages} = this.props;
        const {page} = this.state;
        return (
            <div>
                {this.cutPage(page, pageSize, data).map(d => this.rowRenderer(d))}
                {
                    (maxPages > 0) &&
                    <div className={"d-flex mt-1 align-items-center justify-content-center"}>
                        <Button disabled={page === 0} className={"mb-0"} onClick={this.pagePrev} type={"button"}
                                buttonStyle={"round"}
                                icon={<i className={"fas fa-backward mr-1"}/>}/>
                        <Button disabled={page >= maxPages - 1} className={"mb-0"} onClick={this.pageNext}
                                type={"button"} buttonStyle={"round"}
                                icon={<i
                                    className={"fas fa-forward ml-1"}/>}/>
                    </div>
                }
            </div>
        );
    }
}

_ListDesktop.propTypes = {};

export default _ListDesktop;
