import React from "react";
import "./_input-alike-container.scss"


class InputAlikeContainer extends React.Component {

    render() {
        const {children, error} = this.props;
        return (
            <div className={`input-alike-container ${error ? "error" : ""}`}>
                {children}
            </div>
        )
    }

}


export default InputAlikeContainer;



