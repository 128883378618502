import React, {Component} from 'react';
import {connect} from 'react-redux';
import {EventTypes} from "../../../constans/eventTypes";
import Card from "../../basics/card/Card";
import TableGrid from "../../basics/table-grid/TableGrid";
import moment from "moment";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";

function mapStateToProps(state) {
    return {
        animal: state.animalDocuments.selectedItem,
        heats: state.animalDocuments.events.filter(item => item.EvCode === EventTypes.HEAT),
        lang: state.language.lang.lang,
        employees: state.user.employees
    };
}

class HeatList extends Component {

    dateValueFormatter = value => {
        if (value) return moment(value).format("DD.MM.YYYY HH:mm");
    };

    employeesValueFormatter = value => {
        if (value === "System") return "Fetura Cloud";
        const {employees} = this.props;
        let user = employees.find(e => e.LocalUserID === value);
        return user ? user.userName : value ? value : "";
    };

    render() {
        const {t, heats} = this.props;
        const headers = [
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                _mobileDate: true,
                _mobileHeader: true
            },
            {
                name: t("comment"),
                field: "Comment"
            },
            {
                name: t("eventGrid.operator"),
                field: "OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess()
            }
        ];
        return (
            <Card>
                <div className="d-flex justify-content-between">
                    <h5>{t("eventGrid.heatList")}&nbsp;</h5>
                </div>
                <div>
                    <TableGrid data={heats.sort((a, b) => b.EvTime - a.EvTime)} headers={headers}
                               mobileRow={<DefaultMobileRow/>} showPagination={true} paginationItems={10}
                               scrollOnPageChange={false}/>
                </div>
            </Card>
        );
    }
}

HeatList = connect(
    mapStateToProps,
)(HeatList);

export default withTranslation()(HeatList);
