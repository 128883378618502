import React from "react";
import {ConfirmSignUp} from "aws-amplify-react";
import LoginContainer from "./LoginContainer";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import InfoBox from "../basics/info-box/InfoBox";
import Button from "../basics/button/Button";
import {Collapse} from "react-bootstrap";
import {withTranslation} from "react-i18next";

export class CustomConfirmSignUp extends ConfirmSignUp {

    constructor(props) {
        super(props);

        this.state = {
            resend: false
        }
    }

    onSubmit = e => {
        this.confirm();
        e.preventDefault();
    };

    onResendClick = () => {
        this.resend();
        this.setState({
            resend: true
        })
    };

    showComponent(theme) {
        const {t, onStateChange, authData: {username}, error} = this.props;
        const {resend} = this.state;
        return (
            <LoginContainer onStateChange={onStateChange} showForgotPassword={false}
                            onSendVerificationCodeClick={this.onResendClick}>
                {
                    resend &&
                    <InfoBox>{t("login.resendConfirmationCode")}</InfoBox>
                }
                <Collapse in={!!error}>
                    <div>
                        <InfoBox boxColor={"error"}>{error}</InfoBox>
                    </div>
                </Collapse>
                <form onSubmit={this.onSubmit}>
                    <LabeledInput
                        id="username"
                        key="username"
                        name="username"
                        type="text"
                        label={t("login.login")}
                        onChange={this.handleInputChange}
                        onChangeWithEvent
                        disabled
                        value={username}
                    />
                    <LabeledInput
                        id="code"
                        key="code"
                        name="code"
                        type="text"
                        label={t("login.verificationCode")}
                        onChange={this.handleInputChange}
                        onChangeWithEvent
                    />
                    <Button buttonColor={"primary"}>
                        {t("send")}
                    </Button>
                </form>
            </LoginContainer>
        )
    }

}

export default withTranslation()(CustomConfirmSignUp);