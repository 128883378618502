import React from "react";
import {RequireNewPassword} from "aws-amplify-react";
import LoginContainer from "./LoginContainer";
import Button from "../basics/button/Button";
import InfoBox from "../basics/info-box/InfoBox";
import {Collapse} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import i18next from "i18next";
import {isEqual} from "lodash";
import {withRouter} from "react-router-dom";

class CustomNewPassword extends RequireNewPassword {

    constructor(props) {
        super(props);
        this.state = {
            error: ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {error} = this.props;
        if (prevState.error !== error) {
            this.setState({
                error
            })
        }
    }

    onSubmitSendNewPassword = e => {
        const password = this.submit.password.value;
        const confirm_password = this.submit.confirm_password.value;

        if (isEqual(password, confirm_password) && (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password || ""))) {
            this.change();
            this.props.history.push("/")
        } else {
            if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password || ""))) {
                this.setState({
                    error: i18next.t("signUpView.passwordShouldContain"),
                })
            } else {
                this.setState({
                    error: i18next.t("errors.samePasswords")
                })
            }
        }
        e.preventDefault();
    };

    showComponent(theme) {
        const {error} = this.state;
        const {onStateChange, t} = this.props;
        return (
            <LoginContainer onStateChange={onStateChange} showForgotPassword={false} showSendVerificationCode={false}>
                <Collapse in={!!error}>
                    <div>
                        <InfoBox boxColor={"error"}>{error}</InfoBox>
                    </div>
                </Collapse>
                <form onSubmit={this.onSubmitSendNewPassword} ref={ref => (this.submit = ref)}>
                    <LabeledInput
                        id="password"
                        key="password"
                        name="password"
                        type="password"
                        autocomplete="off"
                        label={t("newPassword")}
                        onChange={this.handleInputChange}
                        onChangeWithEvent
                    />
                    <LabeledInput
                        id="confirm_password"
                        key="confirm_password"
                        name="confirmPassword"
                        type="password"
                        autocomplete="off"
                        label={t("login.repeatPassword")}
                        onChangeWithEvent
                    />
                    <Button buttonColor={"primary"}>
                        {t("send")}
                    </Button>
                </form>
            </LoginContainer>
        )
    }
}

CustomNewPassword = withRouter(CustomNewPassword);
export default withTranslation()(CustomNewPassword);
