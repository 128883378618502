import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import moment from "moment";
import {Col, Row} from "react-bootstrap";

class ListRow extends Component {

    handleOnClick = () => {
        const {onExpand} = this.props;
        if (_.isFunction(onExpand)) {
            onExpand(this.props);
        }
    };

    optionsToProps = ({rtl = false, bold = false, renderer = (value) => value} = {}) => {
        return {
            className: ["text-truncate", rtl ? "rtl" : "", bold ? "font-weight-bold" : ""].join(" "),
            renderer: renderer
        }
    };

    colRenderer = ({type, value, options}) => {
        const {sizing, onExpand} = this.props;
        const hasExpand = _.isFunction(onExpand);
        const {className, renderer} = this.optionsToProps(options || ListRow.defaultProps.options[type]);
        return (
            <Col className={className} xs={hasExpand ? 11 : 12}
                 md={["time", "title"].includes(type) ? sizing[0] : hasExpand ? sizing[1] - 1 : sizing[1]}>{renderer(value, this.props)}</Col>)
    };

    render() {
        const {style, title, timestamp, text, subtitle, onExpand, options = {}} = this.props;
        const hasExpand = _.isFunction(onExpand);
        const shouldHighlight = (timestamp && options.highlightAfter) ? timestamp > options.highlightAfter : false;
        const ColRenderer = this.colRenderer;
        return (
            <div style={style}>
                <Row xs={12} onClick={this.handleOnClick}
                     className={`list-row pb-2 pt-2 text-truncate position-relative${hasExpand ? " pointer" : ""}${shouldHighlight ? " highlight-new" : ""}`}
                >
                    <ColRenderer
                        type={"time"}
                        value={timestamp}
                        options={options.time}
                    />
                    <ColRenderer
                        type={"subtitle"}
                        value={subtitle}
                        options={options.subtitle}
                    />
                    <ColRenderer
                        type={"title"}
                        value={title}
                        options={options.title}
                    />
                    <ColRenderer
                        type={"text"}
                        value={text}
                        options={options.text}
                    />
                    {
                        hasExpand &&
                        <div
                            className={"expand-item"}>
                            <i className={"fas fa-chevron-right"}/>
                        </div>
                    }
                </Row>
            </div>

        );
    }
}

ListRow.propTypes = {
    timestamp: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    sizing: PropTypes.arrayOf(PropTypes.number),
    options: PropTypes.shape({
        highlightAfter: PropTypes.number,
        title: PropTypes.shape({
            rtl: PropTypes.bool,
            renderer: PropTypes.func,
            bold: PropTypes.bool,
            useTranslation: PropTypes.bool

        }),
        time: PropTypes.shape({
            rtl: PropTypes.bool,
            renderer: PropTypes.func,
            bold: PropTypes.bool,
            useTranslation: PropTypes.bool

        }),
        text: PropTypes.shape({
            rtl: PropTypes.bool,
            renderer: PropTypes.func,
            bold: PropTypes.bool,
            useTranslation: PropTypes.bool
        }),
        subtitle: PropTypes.shape({
            rtl: PropTypes.bool,
            renderer: PropTypes.func,
            bold: PropTypes.bool,
            useTranslation: PropTypes.bool
        })
    })
};

ListRow.defaultProps = {
    sizing: [5, 7],
    options: {
        highlightAfter: 0,
        title: {rtl: false, renderer: (value) => value},
        time: {rtl: false, renderer: (value) => _.isFinite(value) ? moment(value).format("DD.MM.YY HH:mm") : "-"},
        text: {rtl: false, renderer: (value) => value, bold: true},
        subtitle: {rtl: false, renderer: (value) => value},
    }
};
export default ListRow;
