import React from "react";
import {connect} from "react-redux";
import ListItem from "../basics/list/list-item/ListItem";
import {Col, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import Highlight from "../basics/highlight/Highlight";
import moment from "moment";
import _ from "lodash";
import AgeCounter from "../basics/age-counter/AgeCounter";
import {withTranslation} from "react-i18next";

const Name = React.memo(({deathTime, deathIcon, name, typeName, animalCount, contentPcs, rfid}) =>
    <Col xs={12} md={4}>
        <h3 className={"opacity-75"}>
            {
                !!deathTime &&
                <span className={"mr-1 opacity-25"}>
                                    <i title={deathTime}
                                       className={deathIcon}/>
                                </span>
            }
            {name}
        </h3>
        <div className={"opacity-75"}>
            {typeName}
            {
                (animalCount !== 1) && <small className={"ml-1"}>
                    <Highlight highlightColor={"secondary"}>
                        {animalCount}{contentPcs}
                    </Highlight>
                </small>
            }
            {!!rfid && <small className={"ml-1 opacity-75"}>RFID: {rfid}</small>}
        </div>
    </Col>
);
const Location = React.memo(({locationName, contentLocation}) => (
    <Col xs={12} md={6} className={"text-right text-md-left"}>
        {locationName}
        <div className={"opacity-50 d-none d-sm-block"}>
            {contentLocation}
        </div>
    </Col>
));

const Age = React.memo(({age, contentAge}) => (
    <Col xs={12} md={2} className={"d-none d-md-block"}>
        {age}
        <div className={"opacity-50 d-none d-sm-block"}>
            {contentAge}
        </div>
    </Col>
));

export class InventoryItem extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {measure, data} = this.props;
        if (measure) {
            if ((data.id !== prevProps.data.id) || (_.get(data, "animals.length", 0) !== _.get(prevProps.data, "animals.length", 0))) {
                measure();
            }
        }
    }

    getAge = () => {
        const {data: {animal, isGroup = false}} = this.props;
        if (!isGroup && animal) {
            const birth = animal.DtaBrthTime;
            const deadOrCurrent = animal.DtaDthTime || +new Date();
            if (birth && deadOrCurrent) {
                return <AgeCounter start={birth} stop={deadOrCurrent}/>
            }
        }
        return "-";

    };

    handleRowClick = () => {
        const {handleRowClick, data} = this.props;
        handleRowClick(data);
    };

    render() {
        const {t, index, style, handleRowClick, data: {animal, locationName, animalCount, name = "", typeName = "", animals = [], isGroup = false}} = this.props;
        const rfid = _.get(animal, "RFID", false);
        const DtaDthTime = animal && animal.DtaDthTime ? moment(animal.DtaDthTime).format("DD.MM.YY") : "";
        const DtaDthIcon = animal && animal.DthRsn === 1 ? "fas fa-dollar-sign" : "fas fa-cross";

        return (
            <ListItem index={index}
                      style={style}
                      className={"col"}>
                {
                    !!isGroup &&
                    <>
                        <Row onClick={this.handleRowClick} className={"pointer"}>
                            <Col xs={12}>
                                <h3 className={"opacity-75"}>
                                    {typeName}{' '}
                                    {name}
                                </h3>
                            </Col>
                        </Row>
                        <div className={"pl-1 pl-sm-3 pl-md-4 pl-lg-5 mb-2"}>
                            {
                                animals.map((a, idx) =>
                                    <InventoryItemExported handleRowClick={handleRowClick}
                                                           index={`${index + 1}.${idx + 1}`}
                                                           data={a}/>
                                )
                            }
                        </div>
                    </>
                }
                {
                    !isGroup &&
                    <Row onClick={this.handleRowClick} className={"pointer"}>
                        <Name name={name} rfid={rfid} deathTime={DtaDthTime} animalCount={animalCount}
                              typeName={typeName} deathIcon={DtaDthIcon} contentPcs={t("pcs")}/>
                        <Location locationName={locationName} contentLocation={t("location")}/>
                        <Age age={this.getAge()} contentAge={t("age")}/>
                    </Row>
                }
            </ListItem>
        );
    }

}

let InventoryItemExported = connect(state => ({
}))(InventoryItem);

InventoryItemExported = withTranslation()(InventoryItemExported);

export default withRouter(InventoryItemExported);
