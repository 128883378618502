import React from "react";
import {SignIn} from "aws-amplify-react";
import Button from "../basics/button/Button";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import LoginContainer from "./LoginContainer";
import InfoBox from "../basics/info-box/InfoBox";
import {Collapse} from "react-bootstrap";
import {withTranslation} from "react-i18next";

export class CustomSignIn extends SignIn {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    onSubmit = e => {
        const {clear} = this.props;
        clear();
        this.signIn(e);
        e.preventDefault();
    };

    getInitialValues = () => {
        const {log, pass} = this.props;
        if (log && pass) {
            this.handleInputChange({target: {name: "username", value: log, type: "text", checked: false}});
            this.handleInputChange({target: {name: "password", value: pass, type: "password", checked: false}});
            return true;
        }
        return false;
    };

    showComponent(theme) {
        const {t, onStateChange, error, log, pass} = this.props;
        const {loading} = this.state;
        const initialValues = this.getInitialValues();
        return (
            <LoginContainer onStateChange={onStateChange} showSignIn={false} showSendVerificationCode={false}>
                <form onSubmit={this.onSubmit}>
                    <Collapse in={!!error}>
                        <div>
                            <InfoBox boxColor={"error"}>{error}</InfoBox>
                        </div>
                    </Collapse>
                    <LabeledInput
                        id="username"
                        key="username"
                        value={initialValues ? log : undefined}
                        name="username"
                        type="text"
                        label={t("login.login")}
                        onChange={this.handleInputChange}
                        onChangeWithEvent
                    />
                    <LabeledInput
                        id="password"
                        key="password"
                        value={initialValues ? pass : undefined}
                        name="password"
                        type="password"
                        label={t("login.password")}
                        onChange={this.handleInputChange}
                        onChangeWithEvent
                    />
                    <Button buttonColor={"primary"} isLoading={loading}>
                        {
                            loading ? t("login.loggingIn") : t("login.signIn")
                        }
                    </Button>
                </form>
            </LoginContainer>
        )
    }

}

export default withTranslation()(CustomSignIn);
