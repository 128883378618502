import React, {Component} from 'react';
import Card from "../../basics/card/Card";
import InfoBox from "../../basics/info-box/InfoBox";
import Select from "../../basics/select/Select";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {connect} from "react-redux";
import animalsDB from "../../../database/animalsDB";
import moment from "moment";
import _ from "lodash";
import {convertRowsToCycles} from "../../../utils/AnimalDocumentsUtils";
import {withTranslation} from "react-i18next";
import SwipeableViews from 'react-swipeable-views';

class SowCardGridMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...SowCardGridMobile.getValues(props),
            index: 0
        }
    }

    static getValues = (props) => {
        const {cycleTable} = props;
        const cycles = !_.isEmpty(cycleTable) ? convertRowsToCycles(cycleTable) : [];
        const cycle = cycles[cycles.length - 1] || null;
        return {cycles, cycle};
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!_.isEqual(nextProps.cycleTable, prevState.cycleTable)) {
            return SowCardGridMobile.getValues(nextProps)
        }
        return null
    }

    getBoarName(insemination) {
        let id = insemination.EvData.BoarID;
        let boar = animalsDB.getAnimalById(id);
        if (boar) return boar.AnmNo1;
        return "-";
    }

    getFallReason(reason) {
        const {fallReasons: {WData}, lang} = this.props;
        let rsn = WData[lang].find(item => item.ID === reason);
        if (rsn) return rsn.Value;
    }

    onSelectChange = (index) => {
        this.setState({
            index: index
        })
    };

    getDate(event) {
        return moment(event.EvTime).format("DD.MM.YYYY");
    }

    cycleRenderer = ({key, cycle}) => {
        return (
            <div key={key} className={"mh-5rem"}>
            {
                cycle &&
                <>
                    {
                        cycle.I.length > 0 &&
                        <div onClick={this.onStateChange}>
                            <h5>{this.props.t("animalDocuments.insemination")}</h5>
                            {
                                cycle.I.map((ins, index) => {
                                    return (
                                        <div key={index}>
                                            {this.getDate(ins)}: {this.getBoarName(ins)}
                                        </div>
                                    )
                                })
                            }
                            <hr/>
                        </div>
                    }
                    {
                        cycle.U.length > 0 &&
                        <>
                            <h5>{this.props.t("usg")}</h5>
                            {
                                cycle.U.map((usg, index) => {
                                    return (
                                        <div key={index} className="position-relative">
                                            {this.getDate(usg)}: <i
                                            className={`fas ${usg.EvData.Pregnant ? 'fa-check' : 'fa-times'}`}/>
                                        </div>
                                    )
                                })
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.P.length > 0 &&
                        <>
                            <h5>{this.props.t("animalDocuments.birth")}</h5>
                            {
                                cycle.P.map((parturition, index) => {
                                    return (
                                        <div key={index}>
                                            {this.getDate(parturition)}
                                            <div>
                                                {this.props.t("alive")}: {parturition.EvData.HealthyCnt}
                                            </div>
                                            <div>
                                                {this.props.t("dead")}: {parturition.EvData.DeadCnt}
                                            </div>
                                            <div>
                                                {this.props.t("mummies")}: {parturition.EvData.MummyCnt}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.AN.length > 0 &&
                        <>
                            <h5>{this.props.t("animalDocuments.activeNipples")}</h5>
                            {
                                cycle.AN.map((nipples, index) => {
                                    return (
                                        <div key={index}>
                                            {this.props.t("amount")}: {nipples.EvData.Nipples}
                                        </div>
                                    )
                                })
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.S.length > 0 &&
                        <>
                            <h5>{this.props.t("separation")}</h5>
                            {
                                cycle.S.map((separation, index) =>
                                    <div key={index}>
                                        {this.getDate(separation)} - {separation.EvData.PiCnt} / {convertWeightUnitTo(separation.EvData.PiWeight, {
                                        unit: UnitTypes.MEDIUM,
                                        showUnit: true
                                    })}
                                    </div>
                                )
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.V.length > 0 &&
                        <>
                            <h5>{this.props.t("fallPiglets")}</h5>
                            {
                                cycle.V.map((fallPiglets, index) =>
                                    <div key={index}>
                                        {this.getDate(fallPiglets)} - {fallPiglets.EvData.Piglets} / {convertWeightUnitTo(fallPiglets.EvData.Weight, {
                                        unit: UnitTypes.MEDIUM,
                                        showUnit: true
                                    })}
                                    </div>
                                )
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.W.length > 0 &&
                        <>
                            <h5>{this.props.t("mommy")}</h5>
                            {
                                cycle.W.map((mommy, index) =>
                                    <div key={index}>
                                        {this.getDate(mommy)} - {mommy.EvData.PiCnt}
                                    </div>
                                )
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.M.length > 0 &&
                        <>
                            <h5>{this.props.t("animalDocuments.mommySeparation")}</h5>
                            {
                                cycle.M.map((separation, index) =>
                                    <div key={index}>
                                        {this.getDate(separation)} - {separation.EvData.PiCnt} / {convertWeightUnitTo(separation.EvData.PiWeight, {
                                        unit: UnitTypes.MEDIUM,
                                        showUnit: true
                                    })}
                                    </div>
                                )
                            }
                            <hr/>
                        </>
                    }
                    {
                        cycle.N.length > 0 &&
                        <>
                            <h5>{this.props.t("noPregnancy")}</h5>
                            {
                                cycle.N.map((noPregnancy, index) => {
                                    return (
                                        <div key={index}>
                                            {this.getDate(noPregnancy)} - {this.getFallReason(noPregnancy.EvData.Reason)}
                                        </div>
                                    )
                                })
                            }
                            <hr/>
                        </>
                    }
                </>
            }
        </div>)
    }

    render() {
        const {cycles = [], index} = this.state;
        return (
            <Card>
                {
                    cycles.length === 0 &&
                    <InfoBox boxColor={"warning"}>{this.props.t("animalDocuments.noDataForAnimal")}</InfoBox>
                }
                <Select value={index} options={cycles.map((row, i) => ({
                    name: `${this.props.t("animalDocuments.cycleNumber")} ${row.cycle}`,
                    value: i
                }))} clearButton={false} onChange={this.onSelectChange}/>
                <SwipeableViews
                    animateHeight
                    index={index}
                    onChangeIndex={this.onSelectChange}
                >
                    {cycles.map((cycle, key) => this.cycleRenderer({key, cycle}))}
                </SwipeableViews>
            </Card>
        );
    }
}

SowCardGridMobile = connect(state => ({
    rows: state.animalDocuments.rows,
    fallReasons: state.dictionary.fallReasons,
    lang: state.language.lang.lang,
    cycleTable: state.animalDocuments.cycleTable,
}))(SowCardGridMobile);

export default withTranslation()(SowCardGridMobile);
