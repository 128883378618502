import React from "react";
import {VerifyContact} from "aws-amplify-react";
import LoginContainer from "./LoginContainer";
import {Col, Collapse, Row} from "react-bootstrap";
import Radio from "../basics/radio/Radio";
import Button from "../basics/button/Button";
import LabeledInput from "../basics/input/labeled-input/LabeledInput";
import InfoBox from "../basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class CustomVerifyContact extends VerifyContact {

    onRadioChange = (val, props, e) => {
        console.log(e.target.value);
        this.handleInputChange(e);
    };

    onSubmitVerify = e => {
        this.verify();
        e.preventDefault();
    };

    onSubmitVerificationCode = e => {
        this.submit();
        e.preventDefault();
    };

    showComponent(theme) {
        const {onStateChange, t, error} = this.props;
        const {verifyAttr} = this.state;
        console.log(this);
        return (
            <LoginContainer onStateChange={onStateChange}>
                <h6 className="flex-wrap">{t("login.contactConfirmRequired")}</h6>
                <Collapse in={!!error}>
                    <div>
                        <InfoBox boxColor={"error"}>{error}</InfoBox>
                    </div>
                </Collapse>
                {
                    !verifyAttr &&
                    <form onSubmit={this.onSubmitVerify}>
                        <Row>
                            <Col xl={6} className="d-flex justify-content-center align-items-center">
                                <Radio label={t("email")} name={"contact"} onChange={this.onRadioChange}
                                       value={"email"}/>
                            </Col>
                            <Col xl={6} className="d-flex justify-content-center align-items-center">
                                <Radio label={t("phoneNumber")} name={"contact"} onChange={this.onRadioChange}
                                       value={"phone_number"}/>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-between align-items-center">
                            <small><a className="pointer" onClick={() => onStateChange("signedIn")}>Pomiń</a></small>
                            <Button buttonColor={"primary"}>
                                {t("send")}
                            </Button>
                        </div>
                    </form>
                }
                {
                    verifyAttr &&
                    <form onSubmit={this.onSubmitVerificationCode}>
                        <InfoBox>{i18next.t("login.verifyCode", {type: (verifyAttr === "email" ? t("login.email") : t("phoneNumber"))})}</InfoBox>
                        <LabeledInput
                            id="code"
                            key="code"
                            name="code"
                            type="text"
                            label={t("login.verificationCode")}
                            onChange={this.handleInputChange}
                            onChangeWithEvent
                        />
                        <Button buttonColor={"primary"}>
                            {t("send")}
                        </Button>
                    </form>
                }
            </LoginContainer>
        )
    }

}

export default withTranslation()(CustomVerifyContact);