import React from "react";
import Input from "../../basics/input/Input";
import {connect} from "react-redux";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {inventoryFilter} from "../../../actions/inventoryActions";
import {withTranslation} from "react-i18next";

class SearchBox extends React.Component {

    state = {
        text: SearchBox.getQueryText(this.props) || ""
    };

    timer = null;

    static getQueryText = (props) => {
        try {
            const query = queryString.parse(props.location.search);
            return query.search || ""
        } catch (e) {
            return ""
        }
    };

    componentDidMount() {
        this.props.dispatch(inventoryFilter(this.state.text));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldText = SearchBox.getQueryText(prevProps);
        const newText = SearchBox.getQueryText(this.props);
        if (oldText !== newText) {
            this.props.dispatch(inventoryFilter(newText));
            this.setState({
                text: newText
            })
        }
    }

    handleOnChange = (newValue) => {
        const {location: {pathname, search}, history} = this.props;
        let searchParams = new URLSearchParams(search);
        if (newValue) {
            searchParams.set("search", newValue);
        } else {
            searchParams.delete("search");
        }
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
    };


    setText = (newValue) => {
        this.setState({
            text: newValue || ""
        }, () => {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.handleOnChange(newValue);
                this.timer = null;
            }, 200);
        })
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {text} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Input placeholder={t("farms.tabs.inventoryTab.filterBy")} onChange={this.setText} value={text}/>
            </div>
        )
    }

}

SearchBox = connect(state => ({
}))(SearchBox);

SearchBox = withTranslation()(SearchBox);

export default SearchBox = withRouter(SearchBox);
