import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Desktop from "./_ListDesktop";
import Mobile from "./_ListMobile";
import _ from "lodash";
import "./_list-full-width.scss";

/**
 * Prosze się wszytrzymać z używaniem tego komponentu - jest we wczesnej wersji i może ulec zmianie
 */
class ListFullWidth extends Component {
    render() {
        const {sizing, options, pageSize, maxPages, data, onExpand, loadMoreCallback, mobile, loading} = this.props;
        const className = `fetura-list-full-width${_.isFunction(onExpand)?" selectable":""}`;
        return (
            <div className={className}>
                {
                    !mobile &&
                    <Desktop sizing={sizing} options={options} loading={loading} loadMoreCallback={loadMoreCallback} onExpand={onExpand}
                             data={data} pageSize={pageSize}
                             maxPages={maxPages}/>

                }
                {
                    mobile &&
                    <Mobile options={options} loading={loading} loadMoreCallback={loadMoreCallback} onExpand={onExpand}
                            data={data} pageSize={pageSize}
                            maxPages={maxPages}/>

                }
            </div>
        );
    }
}

ListFullWidth.propTypes = {
    data: PropTypes.array.isRequired,
    loadMoreCallback: PropTypes.func,
    loading: PropTypes.bool,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number,
    mobile: PropTypes.bool,
    options: PropTypes.object,
    maxPages: PropTypes.number.isRequired,
    sizing: PropTypes.array
};

ListFullWidth.defaultProps = {
    mobile: false
};

export default ListFullWidth;
