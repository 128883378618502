import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import SearchBox from "./mini-components/SearchBox";
import SortMenu from "./mini-components/SortMenu";
import LocationSelect from "./mini-components/LocationSelect";
import {getHeaderTabsHeight} from "../../utils/DOMUtils";
import SwitchActions from "./mini-components/SwitchActions";
import {isMobile} from "../../utils/MobileUtils";

class InventoryActions extends Component {

    render() {
        const top = getHeaderTabsHeight();
        const mobile = isMobile();
        return (
            <Col md={12} className={`pt-1rem ${!mobile ? 'sticky-item' : ''}`} style={{top}}>
                <Row className={"align-items-center justify-content-end"}>
                    <Col xs={12} lg={12} xl={6}>
                        <SearchBox/>
                    </Col>
                    <Col xs={4} lg={4} xl={2}>
                        <SortMenu/>
                    </Col>
                    <Col xs={8} lg={8} xl={4}>
                        <LocationSelect/>
                    </Col>
                    <Col className={"col-auto"}>
                        <SwitchActions/>
                    </Col>

                </Row>

            </Col>
        );
    }
}

export default InventoryActions;
