import React from "react";
import {connect} from "react-redux";
import "./_animals-treatment.scss"
import {withTranslation} from "react-i18next";
import {medicineTypes} from "../../../constans/medicine";
import Tooltip from "../../basics/tooltip/Tooltip";
import {get} from "lodash";

export class AnimalsInTechnologyGroupTreatment extends React.Component{

    getMedicines(props) {
        const {medicines, value} = props;
        return value.map((medicineID) => {
            let medicine = medicines.filter((item) => item.WordID === medicineID && item.WData.Type === medicineTypes.VACCINE)[0];
            if (medicine && medicine.WData.Color) {
                const name = get(medicine, "WData.Name", "");
                return (
                    <Tooltip tooltipContent={name}>
                        <span className="color-square" style={{background: medicine.WData.Color}} key={medicineID}/>
                    </Tooltip>
                )
            }
        }).filter((el) => el);
    }

    render(){
        const {t} = this.props;
        const medicines = this.getMedicines(this.props);
        return (
            <div className="medicines">
                {
                    medicines.length === 0 && t("grid.noMedicine")
                }
                {
                    medicines
                }
            </div>
        )
    }
}

AnimalsInTechnologyGroupTreatment = connect(state => ({
    medicines: state.dictionary.medicine,
    lang: state.language.lang.lang
}))(AnimalsInTechnologyGroupTreatment);

export default withTranslation()(AnimalsInTechnologyGroupTreatment);
