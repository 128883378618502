import React from "react";
import Card from "../../components/basics/card/Card";
import PropTypes from "prop-types";
import {ReactSVG} from "react-svg";
import "./_help-item.scss";
import {Col} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {connect} from "react-redux";

export class HelpSettingsCard extends React.Component {

    onButtonClick() {
        const {modal} = this.props;
        this.props.show(modal);
    }


    render() {
        const {title, icon} = this.props;
        return (
            <Col md={3}>
                <Card onClick={() => this.onButtonClick()} className="help-settings-card-container">
                    <div className="svg-container">
                        <ReactSVG src={icon} loading={() => <svg viewBox="0 0 270.93334 270.93334"/>}/>
                    </div>
                    <h3>
                        {title}
                    </h3>
                </Card>
            </Col>
        )
    }
}

HelpSettingsCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

HelpSettingsCard = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(HelpSettingsCard);

export default HelpSettingsCard;