import React from "react";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import _ from "lodash";
import MenuItem from "../../basics/menu/MenuItem";
import Menu from "../../basics/menu/Menu";
import {inventorySort as inventorySortBy} from "../../../actions/inventoryActions";
import {InventorySortType} from "../../../selectors/inventorySelector";
import {withCookies} from "react-cookie";
import moment from "moment";
import Button from "../../basics/button/Button";
import {withTranslation} from "react-i18next";


class SortMenu extends React.Component {

    constructor(props) {
        super(props);
        const {cookies} = this.props;
        this.state = {
            event: null
        };
        const defaultValue = (cookies.get("inventoryView") || {}).sortBy || InventorySortType.BY_ANIMAL_NUMBER_ASC;
        this.handleChange(defaultValue, false);
    }

    setEvent = (e) => {
        this.setState({
            event: e
        })
    };
    getItems = () => [
        {
            name: this.props.t("number"),
            value: InventorySortType.BY_ANIMAL_NUMBER_ASC
        },
        {
            name: this.props.t("animalType"),
            value: InventorySortType.BY_ANIMAL_TYPE_ASC
        },
        {
            name: this.props.t("location"),
            value: InventorySortType.BY_LOCATION_ASC
        }
    ].filter(o => o);


    handleChange = (value, swapSuffix = true) => {

        let newValue = value;
        const {inventorySort, cookies, dispatch} = this.props;
        if (swapSuffix) {
            if (this.chopEnd(newValue) === this.chopEnd(inventorySort)) {
                newValue = `${this.chopEnd(newValue)}${inventorySort.endsWith("_ASC") ? "_DESC" : "_ASC"}`;
            }
        }
        if (Object.values(InventorySortType).includes(newValue) && inventorySort !== newValue) {
            dispatch(inventorySortBy(newValue));
            const cookie = cookies.get("inventoryView") || {};
            cookie.sortBy = newValue;
            cookies.set("inventoryView", cookie, {
                path: "/",
                expires: moment().add(1, "year").toDate()
            });
        }
    };

    chopEnd = (astring) => {
        return astring.split("_").slice(0, -1).join("_");
    };

    render() {
        const {inventorySort = "", t} = this.props;
        const {event} = this.state;
        let items = this.getItems();
        const name = _.get(items.find(item => this.chopEnd(item.value) === this.chopEnd(inventorySort)), "name", t("farms.tabs.inventoryTab.sortBy"))
        return (
            <>
                <Button title={name} type={"button"} className={"w-100"} onClick={this.setEvent}><i
                    className={`fas fa-flip-vertical fa-sort-amount-${inventorySort.endsWith("_ASC") ? "up" : "down"}`}/><span
                    className={"d-none d-lg-inline ml-1"}>{name}</span></Button>
                <Menu event={event}>
                    {items.map(item => (
                        <MenuItem stopPropagation={true} onClick={() => this.handleChange(item.value)}
                                  icon={this.chopEnd(item.value) === this.chopEnd(inventorySort) ? (inventorySort.endsWith("_ASC") ?
                                      <i className="fas fa-sort-amount-up fa-flip-vertical"/> :
                                      <i className="fas fa-sort-amount-down fa-flip-vertical"/>) : null}>{item.name}</MenuItem>
                    ))
                    }
                </Menu>
            </>
        )

    }


}


SortMenu.propTypes = {
    chamber: PropTypes.object.isRequired
};

SortMenu = connect((state, props) => ({
    inventorySort: state.inventory.inventorySort || InventorySortType.BY_ANIMAL_NUMBER_ASC

}))(SortMenu);

SortMenu = withTranslation()(SortMenu);

export default withCookies(SortMenu);


