import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {get} from "lodash";
import * as usgStates from "validators-schema/Api/constants/usgStates";

class AnimalsInTechnologyGroupUsgResult extends React.Component {

    getClassNameBasedOnResult = (result) => {
        return !result ? 'fa-times' : result === true || usgStates.USG_STATE.POSITIVE ? 'fa-check' : 'fa-redo-alt';
    };

    render() {
        const {value} = this.props;
        const result = get(value, "EvData.Pregnant");
        const className = this.getClassNameBasedOnResult(result);
        return (
            <div className="usgs">
                {
                    value && moment(value.EvTime).format("DD.MM.YYYY HH:mm")
                }
                {
                    value && <i className={`fas fa-fw ${className}`}/>
                }
            </div>
        );
    }
}

export default compose(
    withTranslation()
)(AnimalsInTechnologyGroupUsgResult);
