import React, {Component} from "react";
import {connect} from "react-redux"
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import moment from "moment";
import TableGrid from "../../basics/table-grid/TableGrid";
import AnimalsInTechnologyGroupTreatment from "./AnimalsInTechnologyGroupTreatment";
import {sortAsNumber} from "../../../utils/SortUtils";
import {withTranslation} from "react-i18next";
import {getTechnologyGroupWeeks, getTimeFromInseminationToPartuition} from "../../../utils/SettingsUtils";
import animalsDB from "../../../database/animalsDB";
import {cloneDeep, get, isNil} from "lodash";
import {EventTypes} from "../../../constans/eventTypes";
import Card from "../../basics/card/Card";
import AnimalsInTechnologyGroupUsgResult from "./AnimalsInTechnologyGroupUsgResult";

@connect((state) => {
    return {
        selectedGroup: state.animalDocuments.selectedItem,
        lang: state.language.lang.lang,
        events: state.animalDocuments.events,
        medicines: state.dictionary.currentMedicine,
        noPregnancyReasons: state.dictionary.noPreganancy,
        technologyGroups: state.technologyGroup.technologyGroups,
        fallReasons: state.dictionary.fallReasons
    };
})
export class AnimalsInTechnologyGroupGrid extends Component {

    getTechnologyGroupData(events, selectedGroup, showRemoved) {
        const weeksInCycle = getTechnologyGroupWeeks();
        const daysToBirth = getTimeFromInseminationToPartuition();
        const animals = [];
        if (showRemoved) {
            get(selectedGroup, "Rmvd", []).forEach(animalId => {
                const animal = animalsDB.getAnimalById(animalId);
                animal && (animals.push(animal))
            });
            get(selectedGroup, "AnmList", []).forEach(animalId => {
                const animal = animalsDB.getAnimalById(animalId);
                animal && animal.DtaDthTime && (animals.findIndex(a => a.AnmID === animalId) === -1) && (animals.push(animal))
            });
        } else {
            get(selectedGroup, "AnmList", []).forEach(animalId => {
                const animal = animalsDB.getAnimalById(animalId);
                animal &&
                isNil(animal.DtaDthTime) &&
                ((animals.findIndex(a => a.AnmID === animalId) === -1)) &&
                ((get(selectedGroup, "Rmvd", []).findIndex(AnmID => AnmID === animalId) === -1)) && (animals.push(animal))
            });
        }
        //local helper functions
        const getAllEvents4AnimalWithType = (AnmID, eventType) => {
            return events.filter((e) => e.AnmID === AnmID && e.EvCode === eventType);
        };
        const filterToNumberOfWeeks = (_events, _startTime, _weeksInCycle) => {
            return _events.filter(item => item.EvTime > _startTime && item.EvTime < moment(_startTime).add(_weeksInCycle, "weeks").endOf("day").toDate().getTime());
        };
        const sortEventsAndGetLast = (_events) => {
            return _events.sort((r1, r2) => r1.EvTime < r2.EvTime)[0]
        };
        //end of helper functions
        const array = [];
        for (let i = 0; i < animals.length; i++) {
            let animal = cloneDeep(animals[i]);
            let tmp = {
                animal: animal,
                medicines: []
            };
            let inseminationsList = getAllEvents4AnimalWithType(animal.AnmID, EventTypes.INSEMINATION);
            inseminationsList = filterToNumberOfWeeks(inseminationsList, selectedGroup.StartTime, weeksInCycle).sort((r1, r2) => r1.EvTime > r2.EvTime);
            tmp.lastInsemination = inseminationsList[0];
            if (tmp.lastInsemination) {
                let usgsList = getAllEvents4AnimalWithType(animal.AnmID, EventTypes.USG);
                let weeksFromInseminationToBirth = Math.ceil(daysToBirth / 7);
                usgsList = filterToNumberOfWeeks(usgsList, tmp.lastInsemination.EvTime, weeksFromInseminationToBirth);
                tmp.lastUSG = sortEventsAndGetLast(usgsList);
                if (!showRemoved && (!tmp.lastUSG || (tmp.lastUSG.EvData.Pregnant))) {
                    tmp.expectedDateOfBirth = moment(tmp.lastInsemination.EvTime).add(daysToBirth, "days");
                } else {
                    tmp.removeReason = this.getRemovalReason(animal, selectedGroup, tmp.lastInsemination, tmp.lastUSG, daysToBirth);
                }
                const medicinesSet = new Set();
                const graftingsList = getAllEvents4AnimalWithType(animal.AnmID, EventTypes.GRAFTING);
                graftingsList.forEach(event => {
                    medicinesSet.add(event.EvData.Medicine);
                });
                const treatmentsList = getAllEvents4AnimalWithType(animal.AnmID, EventTypes.TREATMENT);
                treatmentsList.forEach(event => {
                    get(event, "EvData.Treatment", []).forEach(treatment => {
                        medicinesSet.add(treatment.Medicine);
                    })
                });
                tmp.medicines = [...medicinesSet.values()];
            }
            array.push(tmp);
        }
        return array;
    }

    eventsValueFormatter(value) {
        if (value)
            return moment(value).format("DD.MM.YYYY HH:mm")
    }

    expectedDateOfBirthValueFormatter(value) {
        if (value)
            return value.format("DD.MM.YYYY");
    }

    getRemovalReason = (animal, group, insemination, usg, daysToBirth) => {
        const {AnmID} = animal;
        const {StartTime} = group;
        const {t, lang, noPregnancyReasons, fallReasons, events, technologyGroups} = this.props;
        const getAllEvents4AnimalWithType = (AnmID, eventType) => {
            return events.filter(event => event.AnmID === AnmID && event.EvCode === eventType);
        };
        const getReason = (reasonList, reasonId, notFoundText) => {
            const reasons = get(reasonList, `WData[${lang}]`, []);
            const reason = reasons.filter(item => item.ID === reasonId)[0];
            return reason ? reason.Value : notFoundText //"Nie rozpoznano powodu braku ciąży";
        };
        // w przypadku negatywnego badania USG
        if (usg && !usg.EvData.Pregnant) return t("animalDocuments.removeReason.negativeUSG");//return t(""); //"Negatywne badanie USG";
        const technologyGroupsContainingAnimal = technologyGroups.filter(tg => {
            return isNil(tg.DtaDelTime) && tg.StartTime > StartTime && tg.AnmList.includes(AnmID)
        }).sort((t1, t2) => t1.StartTime - t2.StartTime);
        const firstTechnoGroup = technologyGroupsContainingAnimal[0];
        let lastTime = 0;
        // przypisywanie czasu mozliwego ostatniego eventu
        if (firstTechnoGroup) {
            // czas kolejnego startu grupy technologicznej
            lastTime = firstTechnoGroup.StartTime;
        } else {
            // czas od inseminacji do porodu
            // wydaje się być to błędne założenie z racji iż, że po porodzie mogą być jeszcze robione eventy na prosiakach + odsad
            lastTime = moment(insemination.EvTime).add(daysToBirth, "days").toDate().getTime()
        }
        /*
            eventy powinny być brane od początku istnienia zwierzęcia do końca czasu kiedy powinno się znajdować
            w grupie. Inseminacja moze byc wykonana w przód przed np. upadkiem zatem stąd ta zmiana na filter.
         */
        const firstNoPregnancy = getAllEvents4AnimalWithType(AnmID, EventTypes.NO_PREGNANCY)
        //.filter(item => item.EvTime > insemination.EvTime && item.EvTime < lastTime)
            .filter(item => item.EvTime < lastTime)
            .sort((n1, n2) => n1.EvTime - n2.EvTime)[0];
        // powod brak ciazy zgloszony
        if (firstNoPregnancy) {
            return getReason(noPregnancyReasons, firstNoPregnancy.EvData.Reason, t("animalDocuments.removeReason.unknownNoPregnancy"));//t("Nie rozpoznano powodu braku ciazy"))
        }
        const firstFall = getAllEvents4AnimalWithType(AnmID, EventTypes.FALL)
            //.filter(item => item.EvTime > insemination.EvTime && item.EvTime < lastTime)
            .filter(item => item.EvTime < lastTime)
            .sort((n1, n2) => n1.EvTime - n2.EvTime)[0];
        // upadek zgloszony
        if (firstFall) {
            return getReason(fallReasons, firstFall.EvData.Reasn, t("animalDocuments.removeReason.unknownFall")); //t("Nie rozpoznano powodu upadku"));
        }
        const firstSell = getAllEvents4AnimalWithType(AnmID, EventTypes.SELL)
            //.filter(item => item.EvTime > insemination.EvTime && item.EvTime < lastTime)
            .filter(item => item.EvTime < lastTime)
            .sort((n1, n2) => n1.EvTime - n2.EvTime)[0];
        // sprzedaz zloszona
        if (firstSell) {
            return t("sale") //"Sprzedaż";
        }
        const inseminations = getAllEvents4AnimalWithType(AnmID, EventTypes.INSEMINATION)
            .filter(item => item.EvTime < lastTime)
            .sort((n1, n2) => n1.EvTime - n2.EvTime);
        if (inseminations.length > 1) {
            return t("animalDocuments.removeReason.repetition");
        }
        // przypisanie do kolejnej grupy technologicznej
        if (firstTechnoGroup) {
            return t("animalDocuments.removeReason.assignedToOtherTechnoGroup") //"Przypisanie do następnej grupy technologicznej"
        }
        return t("animalDocuments.removeReason.unknownRemove") //"Nie rozpoznano powodu usunięcia"
    };

    handleRowClick = (row) => {
        const {location: {pathname, search}, history} = this.props;
        let searchParams = new URLSearchParams(search);
        searchParams.set("documentsAnimal", row.animal.AnmID);
        searchParams.delete("documentsGroup");
        searchParams.delete("documentsRFID");
        searchParams.delete("documentsTechnoGroup");
        history.replace({
            pathname: pathname,
            search: searchParams.toString()
        });
    };

    render() {
        const {t, showRemoved, events, selectedGroup} = this.props;
        const data = this.getTechnologyGroupData(events, selectedGroup, showRemoved);
        const columnDefs = [
            {
                name: t("number"),
                field: "animal.AnmNo1",
                customSort: sortAsNumber
            },
            {
                name: t("grid.inseminationDate"),
                field: "lastInsemination.EvTime",
                valueFormatter: params => this.eventsValueFormatter(params),
                disableValueFormatterSort: true
            },
            {
                name: t("grid.usg"),
                field: "lastUSG",
                component: props => <AnimalsInTechnologyGroupUsgResult {...props}/>
            }
        ];
        if (showRemoved) {
            columnDefs.push({
                name: t("grid.removeReason"),
                field: "removeReason"
            });
        } else {
            columnDefs.push({
                name: t("grid.expectedDateOfBirth"),
                field: "expectedDateOfBirth",
                valueFormatter: params => this.expectedDateOfBirthValueFormatter(params)
            });
        }
        columnDefs.push({
            name: t("grid.grafting"),
            field: "medicines",
            component: props => <AnimalsInTechnologyGroupTreatment {...props}/>
        });
        return (
            <Card>
                <div className="d-flex justify-content-between">
                    <h5>{showRemoved ? t("animalDocuments.animalsRemovedFromTechnoGroup") : t("animalDocuments.animalsInTechnoGroup")}&nbsp;</h5>
                </div>
                <div>
                    <TableGrid swipePagination={true}
                               data={data}
                               onRowClick={this.handleRowClick}
                               headers={columnDefs} shouldIndex paginationItems={10} showPagination/>
                </div>
            </Card>
        )
    }
}


AnimalsInTechnologyGroupGrid = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(withRouter(AnimalsInTechnologyGroupGrid));
export default withTranslation()(AnimalsInTechnologyGroupGrid)
