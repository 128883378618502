import React, {Component} from 'react';
import PropTypes from "prop-types"

class LoadingListItem extends Component {
    render() {
        const {isLoading, className, icon, useTransition} = this.props;
        const cName = ["loading-list-item", className, useTransition ? "animate" : ""].filter(o=>!!o).join(" ");
        return (isLoading ?
                <div className={cName}>
                    <i className={icon}/>
                </div> : null
        );
    }
}
LoadingListItem.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    useTransition: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string
};

LoadingListItem.defaultProps = {
    useTransition: false,
    className: "",
    icon: "fas fa-circle-notch fa-spin"
};
export default LoadingListItem;
