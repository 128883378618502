import React from "react";
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

ReactGA.initialize("UA-149528907-1");

class GAListener extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

    componentDidMount() {
        // this.sendPageView(this.context.router.history.location);
        // this.context.router.history.listen(this.sendPageView);
    }

    sendPageView = (location) => {
        let pathName = location.pathname;
        ReactGA.set({page: pathName});
        ReactGA.pageview(pathName);
    }

    render() {
        return this.props.children;
    }
}

export default GAListener;